import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Image from '../../components/Image';
import Subfooter from '../../components/Subfooter';
import Hero from '../../components/Hero';
import '../../styles/pages/_about.scss';
import OutboundLink from '../../components/OutboundLink';
import { Link } from 'gatsby';

const AboutPartnershipsPage = () => (
  <Layout title="Partnerships">
    <Hero
      mediaSource={'img-about-lead.jpg'}
      className={'hero--large'}
      color={'blue'}
    >
      <h1 className="pb-1">Partnerships</h1>
      <p>
        As we work to equitably engage students and teachers nationwide in the
        ever-evolving world of biotechnology, Genentech has developed an
        ecosystem of complementary organizations who are all dedicated to
        achieving this collective vision by leaning into specific roles and
        responsibilities.
      </p>
    </Hero>
    <Section>
      {/* Partnerships */}
      <Container>
        <Row className="partnerships__container mb-6 mt-1">
          <Column size={3}>
            <Link target="_blank" to="https://www.air.org/">
              <Image
                filename="logo-partner-air.png"
                className="partnerships__partner-logo"
              />
            </Link>
          </Column>
          <Column size={9}>
            <p className="partnerships__description">
              AIR is one of the world's largest behavioral and social science
              research and evaluation organizations. Founded in 1946 as a
              not-for-profit organization, AIR’s overriding goal is to use the
              best science available to bring the most effective ideas and
              approaches to enhancing everyday life. All work by AIR is
              conducted with strict independence, objectivity and
              non-partisanship. Intellectual diversity of employees enables AIR
              to bring together experts from many fields in the search for
              innovative answers to challenges that span the human life course.
            </p>
          </Column>
        </Row>
        <Row className="partnerships__container mb-6">
          <Column size={3}>
            <Link target="_blank" to="https://babec.org/">
              <Image
                filename="logo-partner-babec.jpg"
                className="partnerships__partner-logo"
              />
            </Link>
          </Column>
          <Column size={9}>
            <p className="partnerships__description">
              Bay Area Bioscience Education Community (BABEC) is a community
              nonprofit founded by high school science teachers in 1996 to
              increase access and equity in science education. BABEC believes in
              enhancing all students’ critical thinking, scientific curiosity,
              and career exploration and preparation through the development and
              support of a teacher community of practice. Through curricula,
              reagent kits, equipment kits, and professional learning, BABEC is
              working to advance biotechnology education for classrooms and
              communities everywhere.
            </p>
          </Column>
        </Row>
        <Row className="partnerships__container mb-6">
          <Column size={3}>
            <Link target="_blank" to="https://www.calacademy.org/">
              <Image
                filename="logo-partner-california-academy.jpg"
                className="partnerships__partner-logo"
              />
            </Link>
          </Column>
          <Column size={9}>
            <p className="partnerships__description">
              The mission of the California Academy of Sciences is to explore,
              explain, and sustain life to students of every age. The Academy is
              an aquarium, planetarium, rainforest, and natural history museum
              in the heart of San Francisco’s Golden Gate Park – and a powerful
              voice for biodiversity research and exploration, environmental
              education, and sustainability across the globe. From field
              research to field trips, the Academy believes in the power of
              science to transform individuals, communities, and ecosystems.
            </p>
          </Column>
        </Row>
        <Row className="partnerships__container mb-6">
          <Column size={3}>
            <Link target="_blank" to="http://www.discoveryeducation.com/">
              <Image
                filename="de-logo-positive.png"
                className="partnerships__partner-logo"
              />
            </Link>
          </Column>
          <Column size={9}>
            <p className="partnerships__description">
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in nearly 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc.,
              Discovery Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners. Explore the future of
              education at
              <OutboundLink to="https://www.discoveryeducation.com">
                www.discoveryeducation.com
              </OutboundLink>
              .
            </p>
          </Column>
        </Row>
        <Row className="partnerships__container mb-6">
          <Column size={3}>
            <Link target="_blank" to="https://www.gene.com/good">
              <Image
                filename="logo-partner-genentech.png"
                className="partnerships__partner-logo"
              />
            </Link>
          </Column>
          <Column size={9}>
            <p className="partnerships__description">
              Considered the founder of the industry, Genentech, (now a member
              of the Roche Group), has been delivering on the promise of
              biotechnology for more than 40 years. Genentech is a biotechnology
              company dedicated to pursuing groundbreaking science to discover
              and develop medicines for people with serious and life-threatening
              diseases. As the founders of the biotechnology industry,
              Genentech’s goal is to use the power of genetic engineering and
              advanced technologies to make medicines that address unmet medical
              needs and help millions of people worldwide.
            </p>
          </Column>
        </Row>
        <Row className="partnerships__container mb-6">
          <Column size={3}>
            <Link target="_blank" to="https://www.igniteducation.org/">
              <Image
                filename="logo-partner-ignited.svg"
                className="partnerships__partner-logo"
              />
            </Link>
          </Column>
          <Column size={9}>
            <p className="partnerships__description">
              Ignited Education provides unique Professional Learning
              opportunities for teachers, company employees, university
              researchers, and nonprofits. Founded in 1985 by 13 Silicon Valley
              CEOs and UC Berkeley, Ignited has created thousands of experiences
              that have transformed how teachers see companies, understand STEM
              in the workplace, and teach these critical skills to their
              students. “Careers in every classroom” is more than just a tagline
              – it’s a way of energizing careers, opening new doors for
              students, and improving lives.
            </p>
          </Column>
        </Row>
        <Row className="partnerships__container mb-2">
          <Column size={3}>
            <Link target="_blank" to="https://www.jff.org/">
              <Image
                filename="logo-partner-jff.svg"
                className="partnerships__partner-logo"
              />
            </Link>
          </Column>
          <Column size={9}>
            <p className="partnerships__description">
              JFF drives change in the American workforce and education systems
              to promote economic advancement for all. JFF concentrates on three
              areas that strengthen the workforce and increase success for
              individuals and employers: Ensuring Equity in Advancement, Meeting
              Employer Needs, and Preparing for the Future of Work. By focusing
              on today's most urgent issues while preparing for the future, JFF
              accelerates the alignment and transformation of the American
              workforce and education systems to ensure access to economic
              advancement for every community.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0">
      <Subfooter content="Creating an international network of diverse organizations united in the mission to take biotech from out of the white coat and laboratory and into every school and community." />
    </Section>
  </Layout>
);

export default AboutPartnershipsPage;
